import React from 'react';

export default function IndexSection__structures14() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 lg:py-32 overflow-hidden bg-gray-900"><div className="container px-4 mx-auto">
    <div className="max-w-lg lg:max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-center">
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div className="max-w-md">
            <h1 className="font-heading text-4xl xs:text-6xl font-bold text-gray-50">
              <span>Customer is Our</span>
              <span className="font-serif italic">Priority</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-lg lg:ml-auto">
            <p className="text-gray-300">Risus viverra justo sagittis vestibulum metus. Massa lacinia eros posuere cursus sed vestibulum massa gravida. Turpis volutpat faucibus hac sed suspendisse convallis vestibulum massa.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4 mb-16 lg:mb-12 items-center">
        <div className="relative w-full lg:w-1/2 pb-16 lg:pb-0 px-4 mb-12 lg:mb-0">
          <div className="hidden lg:block absolute top-1/2 right-0 transform -translate-y-1/2 w-px h-60 bg-gray-500" />
          <div className="lg:hidden absolute bottom-0 left-1/2 transform -translate-x-1/2 h-px w-52 bg-gray-500" />
          <div className="max-w-sm">
            <img className="block mb-6" src="saturn-assets/images/testimonials/quote-white.svg" alt="" /><h5 className="text-xl font-semibold text-white mb-10">Really recommend for agency</h5>
            <p className="text-lg text-gray-300 mb-6">“They are able to help a startup like mine scale and are very responsive to all our unique needs”</p>
            <div className="flex items-center">
              <img className="block w-12 h-12 rounded-full" src="saturn-assets/images/testimonials/avatar-reviews2.png" alt="" /><div className="ml-4">
                <span className="block font-semibold text-white leading-none mb-1">Jhonatan Albert</span>
                <span className="block text-sm text-gray-400">Founder of Saturn</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-sm lg:mx-auto">
            <img className="block mb-6" src="saturn-assets/images/testimonials/quote-white.svg" alt="" /><h5 className="text-xl font-semibold text-white mb-10">Amazing Experience</h5>
            <p className="text-lg text-gray-300 mb-6">“They are able to help a startup like mine scale and are very responsive to all our unique needs”</p>
            <div className="flex items-center">
              <img className="block w-12 h-12 rounded-full" src="saturn-assets/images/testimonials/avatar-reviews1.png" alt="" /><div className="ml-4">
                <span className="block font-semibold text-white leading-none mb-1">Jovanca Azalea</span>
                <span className="block text-sm text-gray-400">CTO of Coca Soft</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button className="inline-block mr-1 w-7 h-1 bg-orange-900" />
        <button className="inline-block mr-1 w-7 h-1 bg-gray-400 hover:bg-gray-200" />
        <button className="inline-block w-7 h-1 bg-gray-400 hover:bg-gray-200" />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

