import React from 'react';

export default function WhoweareSectionStats6() {
    return (
        <React.Fragment>
            <>
                <section className="pt-20 md:pt-32">
  <div className="container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 xl:w-2/5 px-4 mb-16 lg:mb-0">
          <div className="max-w-lg mx-auto lg:mx-0">
            <span className="inline-block py-1 px-3 mb-5 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">👋 MEET SATURN UI LIBRARY</span>
            <h1 className="font-heading text-4xl xs:text-6xl md:text-7xl font-bold mb-5">
              <span>Who We Are</span>
            </h1>
            <p className="text-xl text-gray-500 font-semibold">Metro Digital is a cutting-edge development company rooted in the vibrant city of Toronto, Canada. Our passion for innovation drives us to serve not only local businesses but also extend our expertise across Canada and the United States. Specializing in creating bespoke digital solutions, we have had the privilege of collaborating with professionals from diverse fields—lawyers, doctors, realtors—and empowering various small businesses, including ambitious startup apps, local restaurants, and more.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 xl:w-3/5 px-4">
          <img className="block max-w-4xl mx-auto lg:max-w-none lg:mt-20 xl:mt-0 w-full" src="saturn-assets/images/stats/image-stat1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

