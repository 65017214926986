import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MobileappsSectionNavigations1 from '../components/navigations/MobileappsSectionNavigations1';
import MobileappsSectionFeatures3 from '../components/features/MobileappsSectionFeatures3';
import MobileappsSectionContent4 from '../components/content/MobileappsSectionContent4';
import MobileappsSectionFooters2 from '../components/footers/MobileappsSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Mobileapps() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <MobileappsSectionNavigations1 />
      <MobileappsSectionFeatures3 />
      <MobileappsSectionContent4 />
      <MobileappsSectionFooters2 />
    </React.Fragment>
  );
}

