import React from 'react';

export default function MobileappsSectionFeatures3() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden"><div className="container px-4 mx-auto">
    <div className="max-w-3xl mx-auto text-center">
      <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">Professional Solutions</span>
      <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-24">
        <span>Custom App Development</span>
        <span className="font-serif italic">(Web, Mobile, or AI)</span>
      </h1>
    </div>
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 items-center">
        <div className="w-full lg:w-2/5 xl:w-auto px-4 lg:pb-10 mb-16 lg:mb-0">
          <div className="mx-auto max-w-sm">
            <div className="flex items-center pb-12 mb-12 border-b border-gray-100">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-blue-100 rounded-full">
                <img src="saturn-assets/images/features/icon-element.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Efficient &amp; Tailored</h3>
                <span className="text-sm text-gray-400">Designed to meet your specific needs</span>
              </div>
            </div>
            <div className="flex items-center pb-12 mb-12 border-b border-gray-100">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-orange-100 rounded-full">
                <img src="saturn-assets/images/features/icon-hierarchy.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Secure &amp; Reliable</h3>
                <span className="text-sm text-gray-400">Protecting your data and users</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-gray-300 rounded-full">
                <img src="saturn-assets/images/features/icon-school-bag.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Innovative Design</h3>
                <span className="text-sm text-gray-400">Setting trends, not following</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/5 xl:w-auto mx-auto px-4 xl:-mr-10 mb-16 lg:mb-0">
          <img className="block h-80 md:h-100 lg:h-auto xl:h-150 mx-auto" src="saturn-assets/images/features/phone-picture.png" alt="" /></div>
        <div className="w-full lg:w-2/5 xl:w-auto px-4 lg:pb-10">
          <div className="mx-auto max-w-sm">
            <div className="flex items-center pb-12 mb-12 border-b border-gray-100">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-red-200 rounded-full">
                <img src="saturn-assets/images/features/icon-robot.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Adaptable &amp; Responsive</h3>
                <span className="text-sm text-gray-400">Perfect on any device</span>
              </div>
            </div>
            <div className="flex items-center pb-12 mb-12 border-b border-gray-100">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-yellow-200 rounded-full">
                <img src="saturn-assets/images/features/icon-cam.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Unified Branding</h3>
                <span className="text-sm text-gray-400">Consistency across all platforms</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center bg-green-200 rounded-full">
                <img src="saturn-assets/images/features/icon-flask.svg" alt="" /></div>
              <div>
                <h3 className="text-xl font-semibold">Dedicated Support</h3>
                <span className="text-sm text-gray-400">We're here for you, anytime</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

