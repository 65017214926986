import React from 'react';

export default function IndexSectionFooters10() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden mt-5">
  <img className="absolute top-0 left-0" src="saturn-assets/images/footers/blue-light-left.png" alt="" />
  <img className="absolute bottom-0 left-1/2" src="saturn-assets/images/footers/orange-light-bottom.png" alt="" />
  <div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="pb-8 mb-15 border-b border-gray-100 text-center">
        <a className="inline-block" href="#">
          <img className="h-15" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/Planet-Brand-Logo-1-png-1712510641249.webp" alt="" />
        </a>
      </div>
      <div className="flex flex-wrap -mb-5 -mx-6 items-center justify-center">
        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="customwebsites.html">Our Offerings</a></div>
        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="whoweare.html">Who we are</a></div>
        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="blog.html">Resources</a></div>
        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="#">Pricing</a></div>
        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="contactus.html">Contact Us</a></div>
        <div className="px-6 mb-5" />
      </div>
      <div className="mt-15 text-center">
        <span className="text-gray-500">© 2024 Metro Digital. All rights reserved.</span>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

