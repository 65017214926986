import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HourlysolutionsSectionNavigations1 from '../components/navigations/HourlysolutionsSectionNavigations1';
import HourlysolutionsSectionFeatures3 from '../components/features/HourlysolutionsSectionFeatures3';
import HourlysolutionsSectionContent5 from '../components/content/HourlysolutionsSectionContent5';
import HourlysolutionsSectionFooters2 from '../components/footers/HourlysolutionsSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Hourlysolutions() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <HourlysolutionsSectionNavigations1 />
      <HourlysolutionsSectionFeatures3 />
      <HourlysolutionsSectionContent5 />
      <HourlysolutionsSectionFooters2 />
    </React.Fragment>
  );
}

