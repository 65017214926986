import React from 'react';

export default function CustomwebsitesSectionContent6() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 lg:pt-24 overflow-hidden"><img className="absolute bottom-0 left-0" src="saturn-assets/images/content/light-blue-left.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-md md:max-w-lg mx-auto lg:mx-0">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">RICH TEXT MODULE</span>
            <h1 className="font-heading text-4xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-12">
              <span className="block">"Bring Your Brand to Life Online:</span>
              <span className="block font-serif italic">Bespoke Website Solutions"</span>
            </h1>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400 mb-6">At Metro Digital, we specialize in crafting bespoke websites that embody your brand, engage your audience, and drive your business forward.</p>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400">Our custom website development service is designed to create a digital presence that stands out in the crowded online space.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-md md:max-w-lg mx-auto lg:mr-0">
            <h3 className="text-xl xs:text-3xl font-semibold text-gray-800 mb-6">Why Choose Custom Website Development?</h3>
            <ul className="mb-6"><li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Personalized Design: Your business is unique, and your website should be too. We create custom designs that reflect your brand identity and resonate with your target audience.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Scalability: As your business grows, so should your website. Our solutions are built with future expansion in mind, ensuring your site can evolve with your business.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Optimized Performance: Speed and efficiency are crucial for user experience and SEO. We ensure your site is optimized for peak performance, keeping visitors engaged and improving search rankings.</span>
              </li>
              <li className="flex items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Advanced Features: From e-commerce solutions to membership portals, we can integrate a wide range of functionalities to meet your business needs.</span>
              </li>
            </ul><p className="text-lg text-gray-700 mb-5">We believe in a collaborative approach to app development, engaging with clients to deeply understand their needs and challenges.</p>
            <p className="text-lg text-gray-700 mb-8">Our development process is agile and iterative, allowing for flexibility and continuous improvement based on feedback and testing.</p>
            <img className="block w-full" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-53-59-Create-a-two-dimensional-Pixar-inspired-cartoon-image-that-captures-the-spirit-of-technological-innovation-The-scene-is-set-on-a-computer-screen-fil.webp" alt="" /></div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

