import React from 'react';

export default function BlogSectionFaq3() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 md:py-24 overflow-hidden">
  <img className="absolute top-0 right-0 mt-10 -mr-20 sm:-mr-0" src="saturn-assets/images/faq/star-right.svg" alt="" />
  <div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="text-center mb-20">
        <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">FREQUENTLY ASK QUESTION</span>
        <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold">
          <span>You ask? We</span>
          <span className="font-serif italic">answer</span>
        </h1>
      </div>
      <div className="flex flex-wrap -mx-4 -mb-8">
        <div className="w-full lg:w-1/3 px-4 mb-15 lg:mb-0">
          <div className="flex flex-wrap -mx-2 lg:flex-col lg:max-w-sm border-b lg:border-b-0 lg:border-r border-gray-100">
            <div className="w-full md:w-1/2 lg:w-full px-2 mb-15">
              <h4 className="text-2xl font-semibold text-gray-900 mb-9">Getting Started</h4>
              <ul>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-orange-900 font-semibold" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">Getting Started</span>
                  </a>
                </li>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">How we can collaborate</span>
                  </a>
                </li>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">How to use Coca</span>
                  </a>
                </li>
                <li>
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">How to subscription</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 lg:w-full px-2 mb-15">
              <h4 className="text-2xl font-semibold text-gray-900 mb-9">Understanding</h4>
              <ul>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">Properties</span>
                  </a>
                </li>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">How to set Design Style</span>
                  </a>
                </li>
                <li className="mb-6">
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">How to use Mock up</span>
                  </a>
                </li>
                <li>
                  <a className="flex items-center text-lg text-black font-normal hover:text-orange-900" href="#">
                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={6} cy={6} r={5} stroke="#C3C6CE" strokeWidth={2} /></svg>
                    <span className="ml-3">Preview</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 px-4">
          <div className="max-w-xl xl:max-w-3xl mx-auto lg:mr-0">
            <h2 className="text-5xl font-semibold text-gray-900 mb-15">Getting Started</h2>
            <p className="text-xl text-gray-800 mb-12">
              <span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using</span>
              <a className="inline-block text-red-600 hover:underline" href="#">'Content here, content here'</a>
              <span>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose.</span>
            </p>
            <h5 className="text-2xl font-semibold text-gray-800 mb-6">Various versions have evolved</h5>
            <ul className="text-xl text-gray-800 leading-relaxed mb-12">
              <li>1. Various versions have evolved</li>
              <li>2. Discovered the undoubtable</li>
              <li>3. Loked up one of the more obscure</li>
              <li>4. &amp;rarrhk;️ Enter</li>
            </ul>
            <p className="text-xl text-gray-800">
              <span>Urna cursus tincidunt cursus lectus. Id natoque facilisis id ac vel pretium. Facilisis lacinia congue suscipit tortor pretium neque. Dolor sit sociis porttitor arcu tellus ut sed ac. Et pellentesque in eget urna etiam.</span>
              <a className="inline-block text-orange-800 hover:underline" href="#">A sagittis id tempor</a>
              <span>elit consectetur netus. Sit pulvinar gravida ut rutrum mattis in. Mauris et in in pellentesque. Risus aliquam sapien amet consectetur adipiscing. Dictum nisi ullamcorper nisi vitae fermentum massa urna id. At enim adipiscing leo id orci.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

