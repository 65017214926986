import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CustomwebsitesSectionNavigations1 from '../components/navigations/CustomwebsitesSectionNavigations1';
import CustomwebsitesSectionFeatures3 from '../components/features/CustomwebsitesSectionFeatures3';
import CustomwebsitesSectionContent6 from '../components/content/CustomwebsitesSectionContent6';
import CustomwebsitesSectionInstagramPhotos5 from '../components/instagram-photos/CustomwebsitesSectionInstagramPhotos5';
import CustomwebsitesSectionFooters2 from '../components/footers/CustomwebsitesSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Customwebsites() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <CustomwebsitesSectionNavigations1 />
      <CustomwebsitesSectionFeatures3 />
      <CustomwebsitesSectionContent6 />
      <CustomwebsitesSectionInstagramPhotos5 />
      <CustomwebsitesSectionFooters2 />
    </React.Fragment>
  );
}

