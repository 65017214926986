import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WhoweareSectionNavigations1 from '../components/navigations/WhoweareSectionNavigations1';
import WhoweareSectionStats6 from '../components/stats/WhoweareSectionStats6';
import WhoweareSectionContent3 from '../components/content/WhoweareSectionContent3';
import WhoweareSectionCallToAction4 from '../components/call-to-action/WhoweareSectionCallToAction4';
import WhoweareSectionFooters2 from '../components/footers/WhoweareSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Whoweare() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <WhoweareSectionNavigations1 />
      <WhoweareSectionStats6 />
      <WhoweareSectionContent3 />
      <WhoweareSectionCallToAction4 />
      <WhoweareSectionFooters2 />
    </React.Fragment>
  );
}

