import React from 'react';

export default function IndexSection__structures15() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden"><img className="absolute top-0 right-0" src="saturn-assets/images/features/star-element-right.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-center">
        <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
          <div>
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">WHO WE ARE</span>
            <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
              <span>Innovative Tech</span>
              <span className="font-serif italic">Solutions</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
          <div className="max-w-sm lg:ml-auto">
            <p className="text-lg text-gray-500">In a world where technology evolves daily, having a robust tech framework is crucial.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full xl:w-1/2 px-4 mb-8 xl:mb-0">
          <div className="relative h-100 md:h-125">
            <img className="block h-full w-full rounded-3xl object-cover" src="saturn-assets/images/features/blue-photo.png" alt="" /><div className="absolute top-0 left-0 h-full w-full p-8">
              <div className="flex flex-col items-start justify-between h-full">
                <div>
                  <span className="block mb-3 text-sm text-yellow-800 font-semibold uppercase">FORWARD THINKING</span>
                  <h3 className="text-3xl md:text-4xl text-white font-semibold" contentEditable="true">Driven by innovation</h3>
                </div>
                <a className="relative group inline-block w-full xs:w-auto py-4 px-6 text-orange-900 hover:text-white font-semibold bg-orange-50 rounded-md overflow-hidden transition duration-500" href="whoweare.html">
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500" />
                  <div className="relative flex items-center justify-center">
                    <span className="mr-4">Discover Our Vision</span>
                    <span>
                      {/* SVG arrow remains the same */}
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/2 px-4">
          <div className="flex flex-wrap h-full -mx-4">
            <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
              <div className="flex flex-col h-full">
                <a className="relative block h-full mb-7 pt-8 px-8 pb-5 rounded-3xl bg-green-50 hover:bg-green-100 transition duration-200" href="contactus.html">
                  <div className="flex flex-col h-full justify-between max-w-sm pr-16">
                    <p className="text-sm text-gray-900 mb-10 md:mb-6">Understanding your unique challenges is our priority.</p>
                    <span className="text-3xl font-semibold text-gray-900">Get in Touch</span>
                  </div>
                  {/* Image tag remains unchanged */}
                </a>
                <a className="relative block h-full pt-8 px-8 pb-5 rounded-3xl bg-red-50 hover:bg-red-100 transition duration-200" href="workflow.html">
                  <div className="flex flex-col h-full justify-between max-w-sm pr-16">
                    <p className="text-sm text-gray-900 mb-10 md:mb-6">Transparency is key. Explore how we work to ensure success.</p>
                    <span className="text-3xl font-semibold text-gray-900">Our Process</span>
                  </div>
                  {/* Image tag remains unchanged */}
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <a className="relative block h-full pt-8 px-8 pb-5 rounded-3xl bg-orange-50 hover:bg-orange-100 transition duration-200" href="blog.html">
                <div className="flex h-full flex-col items-start justify-between max-w-sm pr-16">
                  <p className="text-sm text-gray-900 mb-10 md:mb-6">Insights and innovations from our tech and business experts.</p>
                  <span className="text-3xl font-semibold text-gray-900">Explore Our Insights</span>
                </div>
                {/* Image tag remains unchanged */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

