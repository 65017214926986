import React from 'react';

export default function HourlysolutionsSectionContent5() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 lg:pt-24 overflow-hidden"><img className="absolute bottom-0 left-0" src="saturn-assets/images/content/light-blue-left.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-md md:max-w-lg mx-auto lg:mx-0">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">RICH TEXT MODULE</span>
            <h1 className="font-heading text-4xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-12">
              <span className="block">"Flex Your Digital Muscle:&nbsp;</span>
              <span className="block font-serif italic">On-Demand Expertise for Your Web and App Needs"</span>
            </h1>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400 mb-6">Metro Digital offers flexible hourly services for enhancements, revisions, or any custom work your website or app may require.&nbsp;</p>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400">Whether you’re looking to update content, improve functionalities, or add new features, our team is ready to assist on an as-needed basis.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-md md:max-w-lg mx-auto lg:mr-0">
            <h3 className="text-xl xs:text-3xl font-semibold text-gray-800 mb-6">Why Opt for Hourly Services?</h3>
            <ul className="mb-6">
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Cost-Effective: Pay only for the services you need, when you need them, allowing for efficient budget management.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Expert Assistance: Access to a team of experts in web and app development, UI/UX design, and more, ready to tackle any challenge.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Quick Turnaround: We understand the importance of timely updates and strive to deliver enhancements and revisions with a fast turnaround time.</span>
              </li>
              <li className="flex items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Scalability: Easily scale your efforts up or down based on your current needs and priorities.</span>
              </li>
            </ul>
            <p className="text-lg text-gray-700 mb-5">Reach out to us with your requirements, and we'll provide an estimated number of hours needed to complete the task.</p>
            <p className="text-lg text-gray-700 mb-8">Our team will keep you updated on progress and consult you for any major decisions, ensuring the final product aligns with your expectations.</p>
            <img className="block w-full" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-02-58-25-A-close-up-image-of-a-designer-s-hands-sketching-a-wireframe-for-a-custom-website-on-a-digital-tablet-The-tablet-screen-shows-a-complex-layout-with-p.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

