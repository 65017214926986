import React from 'react';

export default function IndexSection__structures17() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden bg-gray-50"><img className="absolute top-0 left-0 mt-44" src="saturn-assets/images/faq/light-blue-left.png" alt="" /><img className="absolute top-0 right-0 mt-10" src="saturn-assets/images/faq/star-right.svg" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-5xl mx-auto">
      <div className="text-center mb-24">
        <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">FREQUENTLY ASK QUESTION</span>
        <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
          <span>You ask? We</span>
          <span className="font-serif italic">answer</span>
        </h1>
      </div>
      <div className="pt-18 sm:pt-24 px-8 sm:px-20 pb-18 bg-white rounded-4xl shadow-lg">
        <button className="flex mb-8 pb-8 group w-full items-start justify-between border-b border-gray-100 text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-orange-900 mb-3">What services does Metro Digital offer?</h3>
            <p className="faq-hidden text-lg text-gray-500 hidden group-hover:block mt-3 text-lg text-gray-500">Metro Digital specializes in custom website and app development, AI solutions, and digital marketing services. We cater to a wide range of industries, including but not limited to healthcare, real estate, legal, and the hospitality sector, providing both B2B and B2C solutions.</p>
          </div>
          <div className="pt-1">
            <span>
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
          </div>
        </button>
        <button className="flex mb-8 pb-8 group w-full items-start justify-between border-b border-gray-100 text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-black group-hover:text-orange-900">How does the project process work at Metro Digital?</h3>
            <p className="hidden group-hover:block mt-3 text-lg text-gray-500">Our project process begins with a thorough understanding of your requirements, followed by design, development, testing, and refinement based on feedback. The process is flexible and tailored to meet your specific needs, budget, and timeframe. For more detailed information, please visit our [Our Workflow] page.</p>
          </div>
          <div className="pt-1">
            <span className="hidden group-hover:block">
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
            <span className="block group-hover:hidden">
              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z" fill="black" /></svg></span>
          </div>
        </button>
        <button className="flex mb-8 pb-8 group w-full items-start justify-between border-b border-gray-100 text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-black group-hover:text-orange-900">How long does it take to complete a website or app development project?</h3>
            <p className="hidden group-hover:block mt-3 text-lg text-gray-500">The timeline for completing a website or app development project varies based on its complexity, features required, and the level of customization. After our initial consultation and requirements gathering phase, we provide a projected timeline for your project. Typically, a basic website can take anywhere from 4 to 6 weeks, while more complex app development projects may take several months.</p>
          </div>
          <div className="pt-1">
            <span className="hidden group-hover:block">
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
            <span className="block group-hover:hidden">
              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z" fill="black" /></svg></span>
          </div>
        </button>
        <button className="flex mb-8 pb-8 group w-full items-start justify-between border-b border-gray-100 text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-black group-hover:text-orange-900">Can Metro Digital work within my budget?</h3>
            <p className="faq-hidden hidden group-hover:block mt-3 text-lg text-gray-500 hidden group-hover:block mt-3 text-lg text-gray-500">We understand the importance of budgeting in project planning and strive to work within your financial parameters without compromising on quality. During our initial consultation, we discuss your budget and outline what can be achieved, ensuring transparency and alignment with your financial expectations.</p>
          </div>
          <div className="pt-1">
            <span className="hidden group-hover:block">
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
            <span className="block group-hover:hidden">
              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z" fill="black" /></svg></span>
          </div>
        </button>
        <button className="flex mb-8 pb-8 group w-full items-start justify-between border-b border-gray-100 text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-black group-hover:text-orange-900">How does Metro Digital incorporate AI into projects?</h3>
            <p className="faq-hidden hidden group-hover:block mt-3 text-lg text-gray-500 hidden group-hover:block mt-3 text-lg text-gray-500">At Metro Digital, we leverage AI to enhance user experiences, automate processes, and provide data-driven insights. The integration of AI into your project depends on your specific needs and goals, such as personalized customer interactions, predictive analytics, or operational efficiencies. Our team is excited to explore innovative AI solutions that can propel your business forward.</p>
          </div>
          <div className="pt-1">
            <span className="hidden group-hover:block">
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
            <span className="block group-hover:hidden">
              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z" fill="black" /></svg></span>
          </div>
        </button>
        <button className="flex group w-full items-start justify-between text-left">
          <div className="max-w-xl pr-5">
            <h3 className="text-xl font-semibold text-black group-hover:text-orange-900">Do you have any terms &amp; conditions?</h3>
            <p className="faq-hidden hidden group-hover:block mt-3 text-lg text-gray-500 hidden group-hover:block mt-3 text-lg text-gray-500">Pretium ac auctor quis urna orci feugiat. Vulputate tellus velit tellus orci auctor vel nulla facilisi ut. Ante nunc risus viverra vivamus. Eros amet at lectus ac ac nibh dignissim.</p>
          </div>
          <div className="pt-1">
            <span className="hidden group-hover:block">
              <svg width={17} height={3} viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z" fill="#FF460C" /></svg></span>
            <span className="block group-hover:hidden">
              <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z" fill="black" /></svg></span>
          </div>
        </button>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

