import React from 'react';

export default function IndexSection__structures13() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden"><img className="hidden sm:block absolute bottom-0 left-0 -mb-24" src="saturn-assets/images/blog/blue-light-left.png" alt="" /><img className="absolute top-0 left-1/2 transform -translate-x-1/2" src="saturn-assets/images/blog/orange-light-center.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-end">
        <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-14 lg:mb-0">
          <div>
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">OUR SERVICES</span>
            <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold">
              <span>Our</span>
              <span className="font-serif italic">Services</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/2 px-4 lg:text-right">
          <a className="relative group inline-block py-4 px-7 font-semibold text-orange-900 hover:text-orange-50 rounded-full bg-orange-50 transition duration-300 overflow-hidden" href="whoweare.html">
            <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500" />
            <span className="relative">See More</span>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4 -mb-12">
        <div className="w-full lg:w-1/3 px-4 mb-8">
          <a className="block max-w-md mx-auto group relative" href="customwebsites.html">
            <div className="absolute bottom-0 left-0 w-full p-5">
              <div className="p-5 bg-white rounded-xl">
                <h4 className="text-xl font-semibold">Websites for Professionals &amp; Small Businesses</h4>
                <div className="hidden group-hover:flex justify-end mt-5 items-center text-orange-900 font-semibold">
                  <span className="mr-2">Learn More</span>
                  <svg width={8} height={12} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.82994 5.28995L2.58994 1.04995C2.49698 0.95622 2.38638 0.881826 2.26452 0.831057C2.14266 0.780288 2.01195 0.75415 1.87994 0.75415C1.74793 0.75415 1.61723 0.780288 1.49537 0.831057C1.37351 0.881826 1.26291 0.95622 1.16994 1.04995C0.983692 1.23731 0.87915 1.49076 0.87915 1.75495C0.87915 2.01913 0.983692 2.27259 1.16994 2.45995L4.70994 5.99995L1.16994 9.53995C0.983692 9.72731 0.87915 9.98076 0.87915 10.2449C0.87915 10.5091 0.983692 10.7626 1.16994 10.9499C1.26338 11.0426 1.3742 11.116 1.49604 11.1657C1.61787 11.2155 1.74834 11.2407 1.87994 11.2399C2.01155 11.2407 2.14201 11.2155 2.26385 11.1657C2.38569 11.116 2.4965 11.0426 2.58994 10.9499L6.82994 6.70995C6.92367 6.61699 6.99806 6.50638 7.04883 6.38453C7.0996 6.26267 7.12574 6.13196 7.12574 5.99995C7.12574 5.86794 7.0996 5.73723 7.04883 5.61537C6.99806 5.49351 6.92367 5.38291 6.82994 5.28995Z" fill="currentColor" /></svg>
                </div>
              </div>
            </div>
            <img className="block w-full h-80 rounded-lg" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/5.png" alt="" /></a>
        </div>
        <div className="w-full lg:w-1/3 px-4 mb-8 xl">
          <a className="block max-w-md mx-auto group relative" href="mobileapps.html">
            <div className="absolute bottom-0 left-0 w-full p-5">
              <div className="p-5 bg-white rounded-xl">
                <h4 className="text-xl font-semibold">Mobile App and AI Software Development</h4>
                <div className="hidden group-hover:flex justify-end mt-5 items-center text-orange-900 font-semibold">
                  <span className="mr-2">Learn More</span>
                  <svg width={8} height={12} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.82994 5.28995L2.58994 1.04995C2.49698 0.95622 2.38638 0.881826 2.26452 0.831057C2.14266 0.780288 2.01195 0.75415 1.87994 0.75415C1.74793 0.75415 1.61723 0.780288 1.49537 0.831057C1.37351 0.881826 1.26291 0.95622 1.16994 1.04995C0.983692 1.23731 0.87915 1.49076 0.87915 1.75495C0.87915 2.01913 0.983692 2.27259 1.16994 2.45995L4.70994 5.99995L1.16994 9.53995C0.983692 9.72731 0.87915 9.98076 0.87915 10.2449C0.87915 10.5091 0.983692 10.7626 1.16994 10.9499C1.26338 11.0426 1.3742 11.116 1.49604 11.1657C1.61787 11.2155 1.74834 11.2407 1.87994 11.2399C2.01155 11.2407 2.14201 11.2155 2.26385 11.1657C2.38569 11.116 2.4965 11.0426 2.58994 10.9499L6.82994 6.70995C6.92367 6.61699 6.99806 6.50638 7.04883 6.38453C7.0996 6.26267 7.12574 6.13196 7.12574 5.99995C7.12574 5.86794 7.0996 5.73723 7.04883 5.61537C6.99806 5.49351 6.92367 5.38291 6.82994 5.28995Z" fill="currentColor" /></svg>
                </div>
              </div>
            </div>
            <img className="block w-full h-80 rounded-lg" src="https://static.shuffle.dev/uploads/files/75/75278e872e55c3e50eba84deefd91c37c6039515/1-png-1712699180220.webp" alt="" /></a>
        </div>
        <div className="w-full lg:w-1/3 px-4 mb-8">
          <a className="block max-w-md mx-auto group relative" href="hourlysolutions.html">
            <div className="absolute bottom-0 left-0 w-full p-5">
              <div className="p-5 bg-white rounded-xl">
                <h4 className="text-xl font-semibold">Custom Development and Consultation</h4>
                <div className="hidden group-hover:flex justify-end mt-5 items-center text-orange-900 font-semibold">
                  <span className="mr-2">Learn More</span>
                  <svg width={8} height={12} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.82994 5.28995L2.58994 1.04995C2.49698 0.95622 2.38638 0.881826 2.26452 0.831057C2.14266 0.780288 2.01195 0.75415 1.87994 0.75415C1.74793 0.75415 1.61723 0.780288 1.49537 0.831057C1.37351 0.881826 1.26291 0.95622 1.16994 1.04995C0.983692 1.23731 0.87915 1.49076 0.87915 1.75495C0.87915 2.01913 0.983692 2.27259 1.16994 2.45995L4.70994 5.99995L1.16994 9.53995C0.983692 9.72731 0.87915 9.98076 0.87915 10.2449C0.87915 10.5091 0.983692 10.7626 1.16994 10.9499C1.26338 11.0426 1.3742 11.116 1.49604 11.1657C1.61787 11.2155 1.74834 11.2407 1.87994 11.2399C2.01155 11.2407 2.14201 11.2155 2.26385 11.1657C2.38569 11.116 2.4965 11.0426 2.58994 10.9499L6.82994 6.70995C6.92367 6.61699 6.99806 6.50638 7.04883 6.38453C7.0996 6.26267 7.12574 6.13196 7.12574 5.99995C7.12574 5.86794 7.0996 5.73723 7.04883 5.61537C6.99806 5.49351 6.92367 5.38291 6.82994 5.28995Z" fill="currentColor" /></svg>
                </div>
              </div>
            </div>
            <img className="block w-full h-80 rounded-lg" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/7.png" alt="" /></a>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

