import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WorkflowSectionNavigations1 from '../components/navigations/WorkflowSectionNavigations1';
import WorkflowSectionCallToAction3 from '../components/call-to-action/WorkflowSectionCallToAction3';
import WorkflowSectionContent9 from '../components/content/WorkflowSectionContent9';
import WorkflowSectionFeatures10 from '../components/features/WorkflowSectionFeatures10';
import WorkflowSectionFooters2 from '../components/footers/WorkflowSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Workflow() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <WorkflowSectionNavigations1 />
      <WorkflowSectionCallToAction3 />
      <WorkflowSectionContent9 />
      <WorkflowSectionFeatures10 />
      <WorkflowSectionFooters2 />
    </React.Fragment>
  );
}

