import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlogSectionNavigations1 from '../components/navigations/BlogSectionNavigations1';
import BlogSectionFaq3 from '../components/faq/BlogSectionFaq3';
import BlogSectionFooters2 from '../components/footers/BlogSectionFooters2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <BlogSectionNavigations1 />
      <BlogSectionFaq3 />
      <BlogSectionFooters2 />
    </React.Fragment>
  );
}

