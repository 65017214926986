import React from 'react';

export default function CustomwebsitesSectionFeatures3() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 pb-20 lg:pb-32 overflow-hidden"><img className="absolute top-0 right-0" src="saturn-assets/images/features/star-element-right.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-md lg:max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-center">
        <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-lg">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-600 bg-blue-100 rounded-full">OUR SERVICES</span>
            <h2 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
              Custom Web Solutions for Professionals
            </h2>
            <p className="mt-4 text-lg text-gray-600">Tailored specifically for doctors, lawyers, and entrepreneurs.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
          <div className="max-w-lg lg:ml-auto">
            <p className="text-lg text-gray-500">Elevate your professional presence online with a custom 5-page website, designed, developed, and hosted to meet your unique business needs. From concept to launch, we provide comprehensive solutions including feedback and revision rounds to ensure your satisfaction.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-21-37-Create-an-image-embodying-the-spirit-of-technological-innovation-in-a-Pixar-cartoon-art-style-The-image-should-feature-a-computer-screen-displaying-c.webp" alt="Custom Design" /><h3 className="text-2xl font-semibold mb-5">Bespoke Design</h3>
            <p className="mb-5 text-gray-500">Unique, professional designs that reflect the caliber of your services.</p>
            <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-blue-600 rounded-full border border-gray-100 hover:border-blue-600 transition duration-200" href="#">
              <span className="mr-3">Discover More</span>
              {/* Arrow icon could be added here */}
            </a>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-27-35-Visualize-a-sleek-professional-technological-device-that-embodies-the-essence-of-full-cycle-software-development-This-two-dimensional-artwork-should.webp" alt="Full-cycle Development" /><h3 className="text-2xl font-semibold mb-5">Full-cycle Solutions</h3>
            <p className="mb-5 text-gray-500">From planning and development to hosting and maintenance, we've got you covered.</p>
            <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-blue-600 rounded-full border border-gray-100 hover:border-blue-600 transition duration-200" href="workflow.html">
              <span className="mr-3">Learn More</span>
              {/* Arrow icon could be added here */}
            </a>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4">
          <div>
            <img className="block mb-6 w-full h-100" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-30-16-Envision-a-two-dimensional-professional-looking-technological-device-that-symbolizes-the-essence-of-full-cycle-software-development-The-device-shoul.webp" alt="Feedback & Revisions" /><h3 className="text-2xl font-semibold mb-5">Feedback &amp; Revisions</h3>
            <p className="mb-5 text-gray-500">Your input is crucial. We offer rounds of revisions to get things just right.</p>
            <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-blue-600 rounded-full border border-gray-100 hover:border-blue-600 transition duration-200" href="#">
              <span className="mr-3">Explore Services</span>
              {/* Arrow icon could be added here */}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

