import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import CustomwebsitesPage from './pages/Customwebsites.js';
import MobileappsPage from './pages/Mobileapps.js';
import HourlysolutionsPage from './pages/Hourlysolutions.js';
import WhowearePage from './pages/Whoweare.js';
import BlogPage from './pages/Blog.js';
import ContactusPage from './pages/Contactus.js';
import WorkflowPage from './pages/Workflow.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/customwebsites" element={<CustomwebsitesPage />} />
         
      <Route path="/mobileapps" element={<MobileappsPage />} />
         
      <Route path="/hourlysolutions" element={<HourlysolutionsPage />} />
         
      <Route path="/whoweare" element={<WhowearePage />} />
         
      <Route path="/blog" element={<BlogPage />} />
         
      <Route path="/contactus" element={<ContactusPage />} />
         
      <Route path="/workflow" element={<WorkflowPage />} />
    </Routes>
  );
}

export default App;
