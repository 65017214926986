import React from 'react';

export default function MobileappsSectionContent4() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 lg:pt-24 overflow-hidden"><img className="absolute bottom-0 left-0" src="saturn-assets/images/content/light-blue-left.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-md md:max-w-lg mx-auto lg:mx-0">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">RICH TEXT MODULE</span>
            <h1 className="font-heading text-4xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-12">
              <span className="block">"Your Vision, Our Code: </span>
              <span className="block font-serif italic">Tailored App Development for Every Platform"</span>
            </h1>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400 mb-6">Whether you’re looking to reach customers on their smartphones, improve business operations with a web application, or leverage the power of AI, Metro Digital is your partner in custom app development.&nbsp;</p>
            <p className="max-w-md text-lg xs:text-3xl font-semibold text-gray-400">Our expertise spans across web, mobile, and AI-driven applications, tailored to your specific objectives.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-md md:max-w-lg mx-auto lg:mr-0">
            <h3 className="text-xl xs:text-3xl font-semibold text-gray-800 mb-6">Why Choose Custom App Development?</h3>
            <ul className="mb-6">
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Versatility: We develop apps for various platforms, including iOS, Android, and web, ensuring your application can reach your audience, no matter the device.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Innovation: Our team stays on the cutting edge of technology, incorporating AI and machine learning to create smart, adaptive apps that offer personalized user experiences.</span>
              </li>
              <li className="flex mb-6 items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Security: We prioritize the security of your app and its users, implementing robust security measures to protect against threats.</span>
              </li>
              <li className="flex items-center">
                <img className="w-6 h-6 mr-4" src="saturn-assets/images/content/check-green.svg" alt="" /><span className="text-base xs:text-lg font-semibold text-gray-900">Integration: Seamlessly integrate with existing systems and third-party services to enhance functionality and user experience.</span>
              </li>
            </ul>
            <p className="text-lg text-gray-700 mb-5">We believe in a collaborative approach to app development, engaging with clients to deeply understand their needs and challenges.</p>
            <p className="text-lg text-gray-700 mb-8"> Our development process is agile and iterative, allowing for flexibility and continuous improvement based on feedback and testing.</p>
            <img className="block w-full" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-45-12-Imagine-a-cutting-edge-smartphone-emphasizing-its-thin-bezels-and-large-vibrant-display-that-showcases-the-latest-in-screen-technology-This-device.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

