import React from 'react';

export default function WorkflowSectionContent9() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 lg:py-24 overflow-hidden"><img className="absolute top-0 left-0 mt-10" src="saturn-assets/images/content/stars-left-top.svg" alt="" /><img className="absolute bottom-0 right-0" src="saturn-assets/images/content/orange-light-right.png" alt="" /><div className="relative container px-4 mx-auto">
    <div className="text-center mb-18">
      <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">RICH TEXT MODULE</span>
      <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-12">
        <span>Our Workflow</span>
        <span className="block font-serif italic">in Detail</span>
      </h1>
    </div>
    <div className="max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-lg">
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">1. Understanding Your Needs</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">The foundation of every successful project lies in a deep understanding of your needs and objectives. We begin with comprehensive requirements gathering, engaging in detailed discussions to understand your vision, target audience, and key functionalities you wish to include. This stage sets the direction for the entire project.</p>
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">2. Crafting the Design</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">With a clear understanding of your requirements, our design team gets to work on creating the initial designs. This involves sketching out the user interface (UI), selecting color schemes, and creating prototypes. We prioritize user experience (UX) to ensure that the final product is not only visually appealing but also intuitive and user-friendly.</p>
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">3. Development Phase</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">Once the designs are approved, our development team steps in to bring these designs to life. Using the latest technologies and coding standards, we ensure that your website or application is built on a solid, scalable, and secure foundation. Throughout this phase, we maintain open lines of communication, providing regular updates on progress.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="max-w-lg">
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">4. Rigorous Testing</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">Quality assurance is key to our process. We conduct thorough testing to identify and fix any bugs or issues. This includes functionality testing, performance testing, and security audits, among others, to ensure that the website or application performs flawlessly across all platforms and devices.</p>
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">5. Refining and Iterating</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">Feedback is a crucial part of our workflow. We present the initial version to you for feedback, refining and iterating based on your input. This iterative process ensures that the final product aligns perfectly with your expectations and is ready for launch.</p>
            <p className="text-2xl font-semibold text-gray-800 mb-6" contentEditable="false">6. Launch and Beyond</p>
            <p className="text-lg text-gray-700 mb-6" contentEditable="false">With everything in place, we proceed to the launch, marking the beginning of your new digital journey. But our relationship doesn't end here. We offer ongoing support and maintenance to ensure your digital assets continue to perform at their best, adapting to the ever-evolving digital landscape.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

