import React from 'react';

export default function WhoweareSectionContent3() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 lg:py-24 bs-section-dragged"><div className="px-4">
    <div className="flex flex-wrap -mx-4">
      <div className="relative w-full lg:w-1/2 px-4 mb-12 lg:mb-0 lg:pr-24 xl:pr-40">
        <div className="max-w-md md:max-w-lg mx-auto lg:mr-0 mb-12 md:mb-20">
          <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">RICH TEXT MODULE</span>
          <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
            <span className="block" contentEditable="false">Our Foundation: Entrepreneurial Spirit and</span>
            <span className="block font-serif italic">Technological Passion</span>
          </h1>
        </div>
        <img className="block w-full" src="https://static.shuffle.dev/uploads/files/58/58776eb10dbfdb9542d5c0feb3d6974cc098a2d0/DALL-E-2024-04-10-03-08-41-In-the-style-of-a-Pixar-cartoon-craft-a-two-dimensional-abstract-image-that-embodies-the-spirit-of-technological-innovation-displayed-on-a-computer.webp" alt="" />
      </div>
      <div className="w-full lg:w-1/2 px-4">
        <div className="max-w-md md:max-w-lg xl:max-w-2xl mx-auto lg:mx-0" contentEditable="false">
          <p className="text-2xl text-gray-800 mb-8" contentEditable="false">At the core of Metro Digital lies an entrepreneurial spirit that thrives on the challenges and opportunities presented by the digital world. We are a team of dreamers and doers who are fascinated by the power of technology and its potential to transform lives. Our journey began with a simple vision: to leverage cutting-edge tools and technology to help individuals and small businesses achieve their fullest potential.</p>
          <p className="text-lg font-semibold text-gray-800 mb-8" contentEditable="false">Bridging Businesses and Customers Through Technology</p>
          <p className="text-lg text-gray-700 mb-8">In an era where technology is omnipresent, the need for businesses to build a robust digital presence is more crucial than ever. Metro Digital stands at the forefront of this digital revolution, crafting personalized, technology-driven strategies that allow businesses to connect with their customers in meaningful ways. Whether it's through innovative website designs, intuitive apps, or dynamic digital marketing strategies, our goal is to help you reach your customers efficiently and effectively.</p>
          <p className="text-lg font-semibold text-gray-800 mb-8" contentEditable="false">Exploring the Frontiers of AI</p>
          <p className="text-lg text-gray-700 mb-8">Our excitement about Artificial Intelligence (AI) and its applications in the digital realm is boundless. AI offers unprecedented opportunities for innovation, from automating routine tasks to creating more personalized user experiences. At Metro Digital, we are committed to exploring these frontiers, dedicating ourselves to understanding and harnessing AI's potential to deliver groundbreaking solutions for our clients.</p>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

